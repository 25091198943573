import React from "react";

const Accessibility = () => {
  return (
    <div>
      <div class="container mx-auto py-8" dir="rtl">
        <div class="text-center">
          <h1 class="text-2xl font-bold">הצהרת נגישות </h1>
        </div>

        <div class="my-4">
          <p>חברת הבנתי הינה חברה למתן שירותים/מוצרים בתחום חינוך</p>
        </div>

        <p>
          בהצהרה זו מטרתנו לייעל את השימוש ולשפר את השירות שלנו בכל הנוגע
          לנגישות ושוויון זכויות לאנשים בעלי מוגבלויות. התאמת הנגישות שלנו בוצעה
          בהתאם לתקנה 35 בתקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות
          לשירות) התשע"ג 2013 לרמה AA בכפוף לשינויים והתאמות שבוצעו במסמך התקן
          הישראלי. 
        </p>

        <div class="my-4">
          <h2 class="text-xl font-bold">אמצעי נגישות הקיימים באתר:</h2>
          <ul class="list-disc pl-6">
            <li>תכני האתר נכתבו בשפה ברורה ונעשה שימוש בפונטים קריאים.</li>
            <li>מבניות האתר בנויה מכותרות, פסקאות ורשימות.</li>
            <li>
              התמצאות באתר היא פשוטה ונוחה וכוללת תפריטים זמינים וּברורים.
            </li>
            <li>קישורים בתחילת הדף המאפשרים דילוג לתוכן.</li>
            <li>תיאור טקסטואלי לתמונות ואייקונים עבור טכנולוגיות מסייעות.</li>
            <li>התאמת האתר לסביבות עבודה ברזולוציות שונות (רספונסיביות).</li>
            <li>כפתורי עצירה והפעלה של גלריות סרטונים.</li>
            <li>
              הוטמעו חוקי ARIA העוזרים לפרש את תוכן האתר בצורה מדויקת וטובה
              יותר.
            </li>
            <li>
              הנגשת תפריטים, טפסים ושדות, היררכיית כותרות, רכיבי טאבים, חלונות
              קופצים ועוד.
            </li>
          </ul>
        </div>
        <div class="my-4">
          <h2 class="text-xl font-bold">שינוי תצוגה באתר:</h2>
          <ul class="list-disc pl-6">
            <li>
              ניתן להגדיל או להקטין את תצוגת האתר באמצעות לחיצה על אחד מכפתורי
              ה-"CTRL" ביחד עם גלגלת העכבר או ביחד עם הסימן "+" עבור הגדלה או
              ביחד עם הסימן "-" עבור הקטנת התצוגה. כל לחיצה תקטין או תגדיל את
              המסך בעשרה אחוזים (10%).
            </li>
            <li>
              שינוי גודל הגופן ייעשה באמצעות שימוש בתפריט הנגישות המצוי באתר.
            </li>
            <li>
              גולשים אשר אין ברשותן עכבר או שאשאינם/ן יכולים לעשות שימוש בעכבר
              יכולים להפעיל את התכונות המצויות באתר על ידי לחיצה על המקש "TAB".
              כל לחיצה תעביר את הסמן אל האפשרות הבאה באתר. לחיצה על מקש
              ה-"Enter" תפעיל את הקישור עליו נמצא הסמן.
            </li>
            <li>
              האתר אינו כולל הבהובים, ריצודים ותכנים בתנועה. במקומות אשר נמצאים
              תכנים כאלה, ניתן לעצור אותם בעמידה עליהם ולחיצה על העכבר או מעבר
              אליהם על ידי מקש ה-"TAB" ולחיצה על מקש ה-"Enter".
            </li>
          </ul>
        </div>
        <div class="my-4">
          <h2 class="text-xl font-bold">התאמת אתר למוגבלי ראייה ושמיעה:</h2>
          <ul class="list-disc pl-6">
            <li>מגדילי ראות (רזולוציה) בסיסיים.</li>
            <li>תוכנות זיהוי קולי.</li>
            <li>חבילות זיהוי קולי של מערכות ההפעלה.</li>
          </ul>
        </div>
        <div class="my-4">
          <h2 class="text-xl font-bold">סייגים לנגישות:</h2>
          <p class="mb-4">
            הנהלת האתר עושה ככל שניתן על מנת לוודא כי כלל הדפים המוצגים יהיו
            מונגשים. יחד עם זאת, יתכן וישנם דפים שטרם הונגשו, או שטרם נמצא פתרון
            טכנולוגי מתאים לצורך הנגשתם. בנוסף, ייתכן ובמודעות חיצוניות, אשר
            הוכנסו על ידי בעלי עסקים המפרסמים באתר, ההנגשה לא תהיה שלמה או
            מספקת. נתקלתם בבעיה? אנחנו כאן כדי לסייע! 
            פרטי אחראי נגישות באתר :
            עופר אביטל
            ofer@shalrom.com
           
          </p>
        </div>
      </div>
    </div>
  );
};

export default Accessibility;
