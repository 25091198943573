import './Modal.css';
import { useState } from 'react';
import TeacherApplicationForm from './TeacherApplicationForm';

function Modal({ setShowModal }) {

    return (
    <div className='modal-overlay'>
        <div className='modal-backdrop' onClick={() => setShowModal(false)}></div>
        <div className='modal-container'>
            <div className='title-close-button'>
                <button onClick={() => setShowModal(false)}> X </button>
            </div>
            <div className='title'>
                <h1>הצטרף/הצטרפי כמורה</h1>
            </div>
            <div className='body'>
                <div className='form-wrapper'>
                    <TeacherApplicationForm setShowModal={ setShowModal } />
                </div>
            </div>
        </div>
    </div>
    )
}

export default Modal;
