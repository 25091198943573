import React from "react";

const Terms = () => {
  return (
    <div dir="rtl" className="h-full w-full p-10">
      <h1 className="mb-4 text-4xl font-bold">תקנון הגרלה</h1>
      <h2 className="mb-2 text-3xl font-bold">1. הגדרות</h2>
      <p>למונחים הבאים בתקנון תהא ההגדרה המפורטת לצידם להלן:</p>
      <ul>
        <li>1.1. "עורך ההגרלה" – "שלרום אביטל בע"מ ח.פ. 514832286..</li>
        <li>1.2. "המפקח" – רו"ח יחיא יחיא.</li>
        <li>
          1.3. "ועדת ההגרלה" – ועדת ההגרלה הכוללת את נציג עורך ההגרלה והמפקח על
          ההגרלה או נציגו.
        </li>
        <li>1.4. "התקנון" - הכוונה למסמך זה.</li>
        <li>
          1.5. "אתר" – אתר האינטרנט של עורך ההגרלה בכתובת
          <a href="https://www.meveen.org">Https://www.meveen.org</a>.
        </li>
        <li>
          1.6. "משתתף" – בגיר/ה מעל גיל 18 שנקלט באתר עורך ההגרלה ועמד בכל תנאי
          ההשתתפות המפורטים בתקנון זה.
        </li>
        <li>
          1.7. "פרטים אישיים" - שם פרטי ושם משפחה, כתובת מגורים מלאה וכן מספר
          טלפון פעיל אחד לפחות, כתובת מייל והסכמה לקבלת הודעות דואר אלקטרוני
          בעלי תוכן שיווקי מעורך ההגרלה וכן מאת החברות מעניקות הפרסים בפעילות.
        </li>
        <li>
          1.8. "הזוכה" – המשתתף אשר שמו עלה בגורל (לפי שיוך מספרי) בעת ביצוע
          ההגרלה.
        </li>
        <li>
          1.9. "הפרס" – הפריט בו יזכה המשתתף אשר שמו יעלה בגורל, הכל כמפורט בפרק
          "מימוש הפרס" להלן.
        </li>
        <li>
          1.10. "יום קבלת הפרס" – כפי שיקבע על ידי עורך ההגרלה לאחר ובסמוך למועד
          ההגרלה וההכרזה על הזוכה ולא יאוחר מ-60 ימים מיום ביצוע ההגרלה ומשלוח
          הודעה לזוכה באמצעות הדואר האלקטרוני וכן בטלפון, בהתאם לפרטים שמסר
          בטופס ההשתתפות.
        </li>
        <li>1.11. "תקופת ההגרלה" – בין התאריכים 28.07.23 ועד ליום 28.08.23.</li>
      </ul>

      <h2 className="mb-2 text-3xl font-bold">
        2. מועד ביצוע ההגרלה ואופן ביצועה
      </h2>
      <p>
        2.1. הגרלה תיערך בשני חלקים: הראשון ביום 15.08 בשעה: 12:00 והשני ביום
        28.08.23 במשרד רו"ח ההגרלה. בנוכחות ועדת ההגרלה. מבלי לגרוע מהאמור לעיל,
        עורך ההגרלה יהיה רשאי להאריך או לשנות את תקופת ההגרלה ו/או תחילתה, לערוך
        הגרלות נוספות ולהוסיף, להפחית או לשנות פרסים, על פי שיקול דעתו הבלעד
      </p>
      <p>לפי, בכפוף לאישור המפקח על ההגרלה ו/או לכל דין.</p>
      <p>
        2.2. ההגרלה תתבצע על ידי שיוך מספר לכל משתתף, כאשר תוכנת מחשב תבחר מספר
        זוכה באופן אקראי.
      </p>
      <p>
        2.3. ועדת ההגרלה תערוך רשימה שמית של הזוכים וכן תגריל 3 שמות שישמשו
        "זוכה מילואים" אשר ישמשו כזוכה חליפי במידת הצורך. זוכה המילואים לא יחשב
        כזוכה אלא אם זוכה כלשהו לא יהיה רשאי לממש את הפרס מכל סיבה שהיא. זכיית
        זוכי המילואים תתבצע לפי סדר הגרלת שמותיהם וזאת מיד עם הודע על פסילת זוכה
        כלשהו.
      </p>
      <p>
        2.4. משתתף שלא יצר קשר עם עורך ההגרלה תוך 60 ימים מיום שנשלחה אליו הודעת
        דוא"ל ו/או לא אותר טלפונית לאחר 3 ניסיונות ו\או לא עמד בתנאי תקנון זה -
        תיפסל זכייתו והוא לא יהיה זכאי לכל פיצוי. במקרה כזה יוכרז זוכה המילואים
        כזוכה בהגרלה. במידה וזוכה המילואים לא יהא זכאי לקבל את הפרס מסיבה כלשהי,
        תבחר ועדת ההגרלה זוכה וזוכה מילואים נוספים באותה דרך כפי שנבחרו בהגרלה
        הראשונה.
      </p>

      <h2 className="mb-2 text-3xl font-bold">
        3. תנאים מוקדמים להשתתפות בהגרלה
      </h2>
      <p>
        3.1. ההשתתפות בהגרלה מותנית בהסכמת המשתתף להיכלל במאגרי הדיוור האלקטרוני
        של עורך ההגרלה ושל החברות מעניקות הפרסים בפעילות.
      </p>
      <p>
        3.2. מילוי פרטי המשתתף בטופס ההשתתפות בהגרלה, מהווה הסכמה מפורשת להיכלל
        במאגרי הדיוור של עורך ההגרלה והחברות מעניקות הפרסים בהגרלה ולקבל מעת לעת
        הודעות המכילות מידע פרסומי.
      </p>
      <p>
        3.3. כל משתתף יהיה רשאי להיגרע בכל עת מרשימות הדיוור וזאת באמצעות משלוח
        הודעה בכתב לכל אחת מהחברות בנפרד. תנאי לקבלת הפרס הוא כי עורך ההגרלה
        יהיה רשאי לצלם ו/או להסריט את הזוכה בעת מימוש הזכייה ו/או את קבלת הפרס
        ולהשתמש בצילומים לצורכי פרסומת וכן להקרינם בטלוויזיה, ברשת האינטרנט ו/או
        בכל מדיה אחרת לצורכי פרסום של עורך ההגרלה ו/או החברות אשר מעניקות פרסים
        במסגרתה.
      </p>
      <p>
        3.4. עצם ההשתתפות במבצע תיחשב להסכמת הזוכה לצילום ופרסומו ו/או הקרנתו
        כאמור.
      </p>
      <p>
        3.5. ככל והזוכה לא יכול להשתתף בצילום כאמור לרבות מטעמי דת או
        אידיאולוגיה, יצטלם בן משפחתו או נציג מטעמו. צילום כאמור (לרבות צילום
        טלוויזיה) יתקיים במועד מימוש הזכייה או במועד אחר, לפי שיקול דעתו של עורך
        ההגרלה, בתיאום עם הזוכה.
      </p>
      <p>
        3.6. פרסום התצלומים שיצולמו כאמור לעיל ו/או פרסום שם הזוכה, ואם הוא קטין
        פרסום שמו ו/או שם הוריו/אפוטרופוסיו, מענם ופרטים נוספים אודותיהם, יעשה
        בהתאם לחוק ובאופן ובמועדים שיקבעו על פי שיקול דעתו של עורך ההגרלה.
      </p>
      <p>
        3.7. מובהר כי עורך ההגרלה יהיה רשאי שלא לצלם ו/או לפרסם כלל את פרטי
        הזוכה ו/או התצלומים שיצולמו.
      </p>
      <p>
        3.8. הוצאות הצילום וכן הוצאות נסיעה בהתאם לתעריף תחבורה ציבורית שידרשו
        לזוכה לצורך הגעה למקום הצילום ישולמו על ידי עורך ההגרלה.
      </p>
      <p>
        3.9. הזוכה לא יהיה זכאי לקבל תמורה כלשהי, למעט החזר הוצאות כאמור לעיל.
      </p>
      
      <h2 className="mb-2 text-3xl font-bold">4. המשתתפים בהגרלה</h2>

      <h2 className="mb-2 text-3xl font-bold">תנאים להשתתפות בהגרלה</h2>
      <p>
        4.1. כל אדם שנרשם שירשם להגרלה באמצעות "טופס הרשמה להגרלה" ע"י רישום
        באתר ואשר שמו ופרטיו יקלטו במערכת עורך ההגרלה עד ליום 15.08.23 שעה 11:00
        ואשר יעמוד בכל התנאים המפורטים בתקנון זה. ישתתף בשני מועדי ההגרלה, למעט
        אם זכה במועד הראשון, וכול אדם שירשם עד 28.08.23 בשעה 11:00 גם במועד
        השני, אף אם אחרי המועד הראשון.
      </p>
      <p>
        4.2. על המשתתף בהגרלה להיות מעל גיל 18 בעת ההרשמה באתר, ולהיות הורה של
        תלמיד במערכת החינוך.
      </p>
      <p>
        4.3. כל משתתף רשאי להשתתף בהגרלה פעם אחת בלבד (למלא טופס אחד), סיכוי
        הזכייה יקבעו בהתאם לפירוט להלן:
      </p>
      <ul>
        <li>4.3.1 סיכוי הזכייה לכול מספר בהגרלה יהיו זהים.</li>
        <li>
          4.3.2 משתתף עם כרטיס הגרלה פיזי בעל חולצה הנושאת את הלוגו של "הבנתי"
          יקבל שני מספר הגרלה נוסף ברישום להגרלה.
        </li>
        <li>
          4.3.3 משתתף שיביא משתתף נוסף פעיל להגרלה, באמצעות האתר, יקבל עוד שלושה
          מספרי הגרלה.
        </li>
        <li>
          4.3.4 משתתף אשר רוכש שעורים מהחברה, יקבל חמישה מספרי הגרלה נוספים עבור
          כול שיעור שרכש. (לדוגמה: אם רכש 10 שעורים יקבל 50 מספרים בנוסף על
          המספרים שברשותו)
        </li>
        <li>
          4.3.5 משתתף שנרשם ללא כרטיס הגרלה פיזי, יהיה זכאי למספר הגרלה אחד,
          למעט המקרים לעיל.
        </li>
      </ul>
      <p>
        4.4 מסירת מספרי הגרלה נוספים תעשה על ידי משלוח כרטיסי הגרלה דיגיטליים
        באמצעות יישום WhatsApp. על המשתתף האחריות ליישום לקבלת הכרטיסים, ובכול
        מקרה הרישום במאגר המידע של עורך ההגרלה הוא הקובע.
      </p>
      <p>4.5 מספרי ההגרלה אישים ולא ניתן להעבירם.</p>
      <p>
        4.6 כל משתתף ראשי להירשם פעם אחת עם כרטיס הגרלה פיזי ו/או דיגיטלי אחד
        בלבד. רישום כפול ימחק ולא ישתתף בהגרלה.
      </p>

      <h3 className="mb-2">
        4.4 מסירת מספרי הגרלה נוספים תעשה על ידי משלוח כרטיסי הגרלה דיגיטליים
        באמצעות יישום WhatsApp. על המשתתף האחריות ליישום לקבלת הכרטיסים, ובכול
        מקרה הרישום במאגר המידע של עורך ההגרלה הוא הקובע.
      </h3>
      <h3 className="mb-2">4.5 מספרי ההגרלה אישים ולא ניתן להעבירם.</h3>
      <h2 className="mb-2 text-3xl font-bold">5. הפרסים</h2>
      <h3 className="mb-2">5.1. הפרסים שיוגרלו בין המשתתפים בהגרלה:</h3>
      <h4>במועד הההגרלה הראשון:</h4>
      <ul>
        <li>הנחה של 90% ברכישת שיעור בודד. זאת בכמות של 15 פרסים</li>
        <li>iPad 10.9</li>
      </ul>
      <h4>במועד ההגרלה ה</h4>
      <ul>
        <li>הנחה של 90% ברכישת שיעור בודד. זאת בכמות של 15 פרסים</li>
        <li>5 שוברים בשווי 200₪ לרכישה ברשת ספור פארם</li>
        <li>מכשיר סוני פלייסטיישן 5</li>
      </ul>
      <h3 className="mb-2">
        5.2. מובהר כי עורך ההגרלה רשאי להוסיף פרסים ו/או להמיר כל פרס בפריט שווי
        ערך בהתאם למחירון לצרכן בעת ביצוע ההגרלה. מובהר כי אין באפשרות הזוכה
        להחליף ו/או להמיר ו/או לשנות ו/או לקבל זיכוי כספי בגין הפרסים שיוגרלו.
      </h3>
      <h3 className="mb-2">5.3 מובהר כי הפרסים:</h3>
      <ul>
        <li>Sony PlayStation 5</li>
        <li>iPad 10.9</li>
      </ul>
      <p>
        מותנים במספר משתתפים מינימלי של 150 ובמספר משתתפים רוכשי שעורים מינימלי
        של 15.
      </p>
      <h2 className="mb-2 text-3xl font-bold">6. פסילת משתתף או זוכה</h2>
      <h3 className="mb-2">
        6.1. עורך ההגרלה, באישור המפקח, יהיה רשאי לפסול משתתף ו/או זוכה אשר
        פרטיו האישיים בטופס ההשתתפות בהגרלה לא מולאו במלואם וכן באם הפרטים שמסר,
        כולם או חלקם, התבררו כשגויים, לפי שיקול דעתו הבלעדי של עורך ההגרלה.
      </h3>
      <h3 className="mb-2">
        6.2. באם יפסל משתתף עוד טרם ביצוע ההגרלה, לא יכלל שמו של המשתתף שנפסל
        בהגרלה. בנוסף, באם יפסל משתתף לאחר ביצוע ההגרלה, אזי זכאותו תיפסל ותעבור
        לזוכה המילואים הראשון ברשימה, לפי סדר פסילת הזוכים וללא קשר לשווי הפרס.
      </h3>
      <h2 className="mb-2 text-3xl font-bold">7. פרסום תוצאות ההגרלה</h2>
      <h3 className="mb-2">
        7.1. שמו של הזוכה יפורסם בדף האינטרנט של עורך ההגרלה וזאת לא יאוחר מ-14
        ימים ממועד ביצוע ההגרלה.
      </h3>
      <h3 className="mb-2">
        7.2. עורך ההגרלה ישלח לכל זוכה הודעה בדבר הזכייה וזאת באמצעות דואר
        אלקטרוני וכן באמצעות הטלפון על פי ה פרטים שמסר בטופס ההשתתפות.
      </h3>
      <h3 className="mb-2">
        7.3. בהודעה יפורט הפריט בו זכה, פרטי התקשרות, כתובת לאיסוף הזכייה וכן
        האפשרות למשלוח הפריט באמצעות משלוח על חשבון הזוכה (בהודעת הזכייה הנ"ל
        יצוין המקום בו ניתן לעיין בדו"ח המפקח על ההגרלה).
      </h3>
      <h2 className="mb-2 text-3xl font-bold">8. קבלת הפרס</h2>
      <h3 className="mb-2">
        8.1. לצורך קבלת הפרס יידרש משתתף אשר יוכרז לאשר באמצעות דוא"ל - לא יאוחר
        מ-60 ימים מיום ביצוע ההגרלה כי קיבל לידיו את הודעת הזכייה.
      </h3>
      <h3 className="mb-2">
        8.2. הזוכה יידרש לחתום על אישור קבלת הפרס בעת קבלתו ולהצהיר על עמידתו
        בתנאי התקנון, כתנאי לקבלת הפרס, בנוסח שייקבע על ידי עורך ההגרלה.
      </h3>
      <h2 className="mb-2 text-3xl font-bold">9. אחריות</h2>
      <h3 className="mb-2">
        9.1. עורך ההגרלה, לרבות עובדיו, יועציו, מי שפועל מטעמו והמפקח, אינם
        אחראים ולא יהיו אחראים באופן כלשהו לפעילותה התקינה של רשת האינטרנט
        הארצית והעולמית, ולא יישאו באחריות להפסקות ו/או הפרעות כלשהן במתן איזה
        מהשירותים הקשורים בהגרלה ו/או לנזקים כלשהם, ישירים ו/או עקיפים, לרבות
        הוצאות ישירות ו/או עקיפות, אשר ייגרמו למשתתף במבצע בגין שיבושים ו/או
        הפרעות כאמור במידה והדבר ימנע מאדם כלשהו להשתתף בהגרלה.
      </h3>
      <h3 className="mb-2">
        9.2. עורך ההגרלה, לרבות עובדיו, יועציו, מי שפועל מטעמו והמפקח, לא יהיו
        אחראים באופן כלשהו לכל תקלה ו/או טעות שיגרמו בשוגג בקשר עם ההגרלה.
      </h3>
      <h3 className="mb-2">
        9.3. מבלי לגרוע מכלליות האמור לעיל, לא יהיו המנויים בסעיף זה אחראים על
        כל נזק ישיר ו/או עקיף, לרבות הוצאות ישירות או עקיפות, שיגרמו למאן דהוא
        עקב תקלה ו/או טעות כאמור.
      </h3>
      <h3 className="mb-2">
        9.4. במקרה של תקלה ו/או טעות כאמור, יהיה עורך ההגרלה רשאי, עפ"י שיקול
        דעתו הבלעדי והמוחלט ובאישור המפקח על ההגרלה, לבטל כל הגרלה, להפחית ו/או
        להגדיל את הפרס, לקבוע כי טופסי ההשתתפות מסוג מסוים או ממועדים מסוימים לא
        ישתתפו בהגרלה ו/א
      </h3>
    </div>
  );
};

export default Terms;
