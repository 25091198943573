import './StudentModal.css';
import { useState } from 'react';
import StudentApplicationForm from './StudentApplicationForm';

function StudentModal({ setShowStudentModal }) {

    return (
    <div className='modal-overlay'>
        <div className='modal-backdrop' onClick={() => setShowStudentModal(false)}></div>
        <div className='modal-container'>
            <div className='title-close-button'>
                <button onClick={() => setShowStudentModal(false)}> X </button>
            </div>
            <div className='title'>
                <h1>רישום תלמיד/ה</h1>
            </div>
            <div className='body'>
                <div className='form-wrapper'>
                    <StudentApplicationForm setShowModal={ setShowStudentModal } />
                </div>
            </div>
        </div>
    </div>
    )
}

export default StudentModal;
