import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import './StudentApplicationForm.css';
import { sendFormRequest } from './api';

const domain = 'https://api.meveen.org';

const StudentApplicationForm = ( setShowModal=null ) => {
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    password: Math.random().toString(36).slice(-8),
    phone_number: '',
    gender: ''
  });

  const [studentData, setStudentData] = useState({
    parents: [],
    lessons_number: '',
    social_media_profile: '',
    status: '',
    grade: ''
  });

  const [parentData, setParentData] = useState({
    first_name: '',
    last_name: '',
    password: Math.random().toString(36).slice(-8),
    phone_number: '',
  });

  const [gradeOptions] = useState([
    { value: '1', label: 'א' },
    { value: '2', label: 'ב' },
    { value: '3', label: 'ג' },
    { value: '4', label: 'ד' },
    { value: '5', label: 'ה' },
    { value: '6', label: 'ו' },
    { value: '7', label: 'ז' },
    { value: '8', label: 'ח' },
    { value: '9', label: 'ט' },
    { value: '10', label: 'י' },
    { value: '11', label: 'יא' },
    { value: '12', label: 'יב' },
    { value: '13', label: 'יג' },
    { value: '14', label: 'יד' },
  ]);

  const [lessonOptions] = useState([
    { value: '1', label: '1-2' },
    { value: '3', label: '3-5' },
    { value: '5', label: '5-10' },
    { value: '10', label: 'מעל 10' },
  ]);

  const [statusOptions] = useState([
    { value: 'E', label: 'תלמיד/ה יסודי' },
    { value: 'M', label: 'תלמיד/ה חטיבת ביניים' },
    { value: 'H', label: 'תלמיד/ה תיכון' },
    { value: 'P', label: 'מכינה' },
    { value: 'F', label: 'תואר ראשון' },
    { value: 'S', label: 'תואר שני' },
    { value: 'G', label: 'השכלה כללית' },
  ]);

  const [genderOptions] = useState([
    { value: 'M', label: 'זכר' },
    { value: 'F', label: 'נקבה' },
    { value: 'O', label: 'מעדיף/פה לא לומר' },
  ]);

  const [userOptions] = useState([
    { value: 'S', label: 'תלמיד' },
    { value: 'P', label: 'הורה' },
  ]);

  const [allCities, setAllCities] = useState([]);
  const [streets, setStreets] = useState([]);
  const [parentStreets, setParentStreets] = useState([]);
  const [error, setError] = useState(null);
  // const [sent, setSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [city, setCity] = useState(null);
  const [parentCity, setParentCity] = useState({
    label: null,
    value: null
  });
  const [showAddressInput, setShowAddressInput] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    city: null,
    id: null,
  });
  const [selectedParentLocation, setSelectedParentLocation] = useState({
    city: null,
    id: null,
  });
  const [addressDetails, setAddressDetails] = useState({
    house: '',
    floor: '',
    apartment: '',
    entrance: '',
    intercom: '',
  });
  const [parentAddressDetails, setParentAddressDetails] = useState({
    city: '',
    street: '',
    house: '',
    floor: '',
    apartment: '',
    entrance: '',
    intercom: '',
  });
  const [selectedStreet, setSelectedStreet] = useState(null);
  const [selectedParentStreet, setSelectedParentStreet] = useState(null);
  const [formattedSocialMedia, setFormattedSocialMedia] = useState('');
  const [selectedUserRole, setSelectedUserRole] = useState(userOptions[0]);
  const [passedLocation, setPassedLocation] = useState(false);
  const [visiblePhoneNumber, setVisiblePhoneNumber] = useState({
    student: '',
    parent: ''
  });
  const [isValid, setIsValid] = useState({
    studentError: '',
    studentPassed: true,
    parentError: '',
    parentPassed: true
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await sendFormRequest(`${domain}/api/location/cities/`, 'GET');

        if (response.ok) {
          const data = await response.json();
          setAllCities(data);
        } else {
          console.error('Failed to fetch cities');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchStreets = async () => {
        try {
          if (parentCity.value) {
            const response = await sendFormRequest(
              `${domain}/api/location/cities/${parentCity.value}/streets/`,
              'GET'
            );
            if (response.ok) {
              const data = await response.json();
              setParentStreets(data);
            } else {
              console.error('Failed to fetch streets');
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

    if (parentCity.value) {
      fetchStreets();
    }
  }, [parentCity.value]);

  useEffect(() => {
    const fetchStreets = async () => {
        try {
          if (city) {
            const response = await sendFormRequest(
              `${domain}/api/location/cities/${city.value}/streets/`,
              'GET'
            );
            if (response.ok) {
              const data = await response.json();
              setStreets(data);
            } else {
              console.error('Failed to fetch streets');
            }
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };

    if (city) {
      fetchStreets();
    }
  }, [city]);

  const formatSocialMediaLink = (input) => {
    let formattedLink = input.trim();

    if (!formattedLink.startsWith('http://') && !formattedLink.startsWith('https://')) {
      formattedLink = 'https://' + formattedLink;
    }

    return formattedLink;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleParentChange = (e) => {
    const { name, value } = e.target;
    setParentData({
      ...parentData,
      [name]: value,
    });
  };

  const handlePhoneChange = (e) => {
    const { name, value } = e.target;
    
    const pattern = /^(972|05)/;
    const countryPattern = /^(972)/;
  
    let inputValue = value.replace(/[^\d+]/g, '').slice(0, 13);
    let numericValue = inputValue.replace(/\D/g, '');
  
    const isValidInput = pattern.test(numericValue);
  
    let errorMessage = '';
    let formattedNumber = '';
  
    if (isValidInput) {
      if (countryPattern.test(numericValue)) {
        formattedNumber = `+972-${numericValue.slice(3, 5)}-${numericValue.slice(5, 8)}-${numericValue.slice(8, 10)}-${numericValue.slice(10, 12)}`;
      } else {
        formattedNumber = `05${numericValue.slice(2, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 8)}-${numericValue.slice(8, 10)}`;
      }
    } else if (inputValue.length > 3) {
      errorMessage = "נא להזין מספר טלפון ישראלי חוקי שמתחיל ב-972 או 05.";
    } else {
      formattedNumber = inputValue;
    }
  
    if (name === 'student-phone') {
      setVisiblePhoneNumber({
        student: formattedNumber.replace(/-+$/, '')
      });

      setIsValid({
        studentError: errorMessage,
        studentPassed: isValidInput,
      });
    } else {
      setVisiblePhoneNumber({
        parent: formattedNumber.replace(/-+$/, '')
      });

      setIsValid({
        parentError: errorMessage,
        parentPassed: isValidInput,
      });
    }
  
    if (isValidInput) {
      numericValue = countryPattern.test(numericValue) ? 
      numericValue.slice(3) : numericValue.slice(1);
  
      if (numericValue.length === 9) {
        if (name === 'student-phone') {
          setFormData({
            ...formData,
            phone_number: `+972${numericValue}`,
          });
        } else {
          setParentData({
            ...parentData,
            phone_number: `+972${numericValue}`,
          });
        }
      }
    }
  };

  const handleParentCityChange = (selectedCity) => {
    setParentCity(selectedCity);
    setSelectedParentStreet(null);
  };

  const handleCityChange = (selectedCity) => {
    setCity(selectedCity);
    setSelectedStreet(null);
  };

  const handleParentStreetChange = (selectedStreet) => {
    setSelectedParentStreet(selectedStreet);
  };

  const handleStreetChange = (selectedStreet) => {
    setSelectedStreet(selectedStreet);
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
  
    if (!isChecked) {
      setAddressDetails({
        house: '',
        floor: '',
        apartment: '',
        entrance: '',
        intercom: '',
      });
      
      setSelectedStreet(null);
    }
  
    setShowAddressInput(isChecked);
  };
  

  const formStyle = {
    flexDirection: selectedUserRole.value === 'S' ? 'row-reverse' : 'row',
  };

  const handleUserRoleChange = (selectedOption) => {
    setSelectedUserRole(selectedOption);
  };

  const handleParentAddressDetailsChange = (e) => {
    const { name, value } = e.target;
    
    setParentAddressDetails({
      ...parentAddressDetails,
      [name]: value
    })
  };

  const handleAddressDetailsChange = (e) => {
    const { name, value } = e.target;
    
    setAddressDetails({
      ...addressDetails,
      [name]: value
    })
  };

  const handleSocialMediaChange = (e) => {
    const input = e.target.value;
    setFormattedSocialMedia(formatSocialMediaLink(input));

    setStudentData({
      ...studentData,
      social_media_profile: formatSocialMediaLink(input),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setPassedLocation(false);

    let locationResponse;
    let parentLocationResponse;

    if (!formData.phone_number || formData.phone_number.length !== 13) {
        setError('בבקשה הכנס/י מספר טלפון תקין.');
        setIsLoading(false);
        return;
      }

    if (showAddressInput && parentCity.value) {
      try {
          if (!selectedParentLocation.id || selectedParentLocation.city !== parentCity.value) {
            const locationData = {
              city: parentCity.value,
              street: selectedParentStreet ? selectedParentStreet.value : null,
              house_number: parentAddressDetails.house.length > 0 ? parentAddressDetails.house : null,
              apartment_number: parentAddressDetails.apartment.length > 0 ? parentAddressDetails.apartment : null,
              floor: parentAddressDetails.floor.length > 0 ? parentAddressDetails.floor : null,
              entrance: parentAddressDetails.entrance.length > 0 ? parentAddressDetails.entrance : null,
              intercom_code: parentAddressDetails.intercom.length > 0 ? parentAddressDetails.intercom : null
            };
      
            const filteredLocationData = Object.fromEntries(
              Object.entries(locationData).filter(([key, value]) => value !== null)
            );

            parentLocationResponse = await sendFormRequest(
              `${domain}/api/location/locations/`, 'POST', filteredLocationData
              );
      
            const locationDataJson = await parentLocationResponse.json();
            setSelectedParentLocation({
              city: city.value,
              id: locationDataJson.id
            });
          }
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
    }
  
    try {
        if (!selectedLocation.id || selectedLocation.city !== city.value) {
          const locationData = {
            city: city.value,
            street: selectedStreet ? selectedStreet.value : null,
            house_number: addressDetails.house.length > 0 ? addressDetails.house : null,
            apartment_number: addressDetails.apartment.length > 0 ? addressDetails.apartment : null,
            floor: addressDetails.floor.length > 0 ? addressDetails.floor : null,
            entrance: addressDetails.entrance.length > 0 ? addressDetails.entrance : null,
            intercom_code: addressDetails.intercom.length > 0 ? addressDetails.intercom : null
          };
    
          const filteredLocationData = Object.fromEntries(
            Object.entries(locationData).filter(([key, value]) => value !== null)
          );

            locationResponse = await sendFormRequest(
              `${domain}/api/location/locations/`, 'POST', filteredLocationData
              );
      
            const locationDataJson = await locationResponse.json();
            setSelectedLocation({
              city: city.value,
              id: locationDataJson.id
            });
          }
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
    }

    setPassedLocation(true);
  };

  useEffect(() => {
    let parentResponse;

    const submitForm = async () => {
      setPassedLocation(false);
      if (parentData.first_name || parentData.phone_number) {

        if (parentData.phone_number.length ===! 13) {
            setError('נא להזין מספר טלפון נייד חוקי של הורה.');
            setIsLoading(false);
            return;
          }
        
        if (selectedParentLocation.id) {
          parentResponse = await sendFormRequest(`${domain}/api/student/parents/`, 'POST', {
          user: {
            ...parentData,
            address: selectedParentLocation.id
          }
        });
        } else {
          parentResponse = await sendFormRequest(`${domain}/api/student/parents/`, 'POST', {
          user: {
            first_name: parentData.first_name,
            last_name: parentData.last_name,
            phone_number: parentData.phone_number,
            password: parentData.password
          }
        });
        }

        const parentResponseData = await parentResponse.json();

        const response = await sendFormRequest(`${domain}/api/student/students/`, 'POST', {
          user: {
            ...formData,
            address: selectedLocation.id
          },
          parents: [parentResponseData.id],
          lessons_number: studentData.lessons_number.value,
          social_media_profile: studentData.social_media_profile,
          status: studentData.status.value,
          grade: studentData.grade
        });
  
        if (response.ok) {
          // setSent(true);
          setError(null);
          setIsLoading(false);
          setSuccessMessage('!הבקשה הוגשה בהצלחה');
          setTimeout(() => {
            setSuccessMessage(null);
            if (!setShowModal === null) {
              setShowModal(false);
            }
          }, 2000);
        } else {
          const errorData = await response.json();
          const formattedError = Object.entries(errorData)
            .map(([field, message]) => {
              let formattedMessage;

              if (Array.isArray(message) && message.length > 0) {
                formattedMessage = message.join('\n');
              } else if (typeof message === 'object' && message !== null) {
                formattedMessage = Object.values(message).join('\n');
              } else {
                formattedMessage = String(message);
              }

              return `${formattedMessage}`;
            })
            .join('\n');
          setError(formattedError);
          setIsLoading(false);
        }
      } else {
        const response = await sendFormRequest(`${domain}/api/student/students/`, 'POST', {
          user: {
            ...formData,
            address: selectedLocation.id
          },
          lessons_number: studentData.lessons_number.value,
          social_media_profile: studentData.social_media_profile,
          status: studentData.status.value,
          grade: studentData.grade
        });
  
        if (response.ok) {
          setError(null);
          setIsLoading(false);
          setSuccessMessage('!הבקשה הוגשה בהצלחה');
          setTimeout(() => {
            setSuccessMessage(null);
            if (!setShowModal === null) {
              setShowModal(false);
            }
          }, 2000);
        } else {
          const errorData = await response.json();
          const formattedError = Object.entries(errorData)
            .map(([field, message]) => {
              let formattedMessage;

              if (Array.isArray(message) && message.length > 0) {
                formattedMessage = message.join('\n');
              } else if (typeof message === 'object' && message !== null) {
                formattedMessage = Object.values(message).join('\n');
              } else {
                formattedMessage = String(message);
              }

              return `${formattedMessage}`;
            })
            .join('\n');
            setIsLoading(false);
          setError(formattedError);
        }
      }
    };

    if (selectedLocation.id && passedLocation) {
      submitForm(); 
    }
  }, [selectedLocation.id, passedLocation, isLoading]);

  const renderParentPhoneNumberWarningText = () => {
    const { parentError, parentPassed } = isValid;

    if (!parentPassed) {
      return <div className="warning-text">{ parentError }</div>;
    }

    return null;
  };

  const renderStudentPhoneNumberWarningText = () => {
    const { studentError, studentPassed } = isValid;

    if (!studentPassed) {
      return <div className="warning-text">{ studentError }</div>;
    }

    return null;
  };

  return (
    <form className="student-application-form" style={formStyle} onSubmit={handleSubmit} method="POST">
      <div className="left-side">
        { selectedUserRole.value === "S" ?  
          <>
          <label style={{marginTop:45}}>:?מי ממלא את הבקשה</label>
          <Select
            options={userOptions}
            value={selectedUserRole}
            onChange={handleUserRoleChange}
            className='select-city-input right-align'
            placeholder='בחר/י...'
            isRtl={true}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'right',
              }),
              singleValue: (provided) => ({
                ...provided,
                textAlign: 'right',
              }),
            }}
            required
          />
          </> :
          <h3>:פרטים אישיים נוספים - { selectedUserRole.value === "P" ? "תלמיד/ה" : "הורה" }</h3>
        }
        

        <label>:אימייל*</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />

        <label>:שם פרטי*</label>
        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />

        <label>:שם משפחה*</label>
        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />

        <label>:נייד*</label>
        { renderStudentPhoneNumberWarningText() }
        <input
          type="tel"
          name='student-phone'
          id='react-tel-input'
          className="react-tel-input"
          value={visiblePhoneNumber.student}
          onChange={handlePhoneChange}
          required
        />

        <label>:עיר*</label>
        <Select
          options={allCities.map((city) => ({ value: city.id, label: city.name }))}
          value={city}
          noOptionsMessage={() => 'אין אפשרויות זמינות'}
          onChange={handleCityChange}
          className="select-city-input right-align"
          placeholder='בחר/י...'
          isRtl={true}
          isClearable={true}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
            option: (provided, state) => ({
              ...provided,
              textAlign: 'right',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'right',
            }),
          }}
          required
        />

      <div className="checkbox-container">
        <input
          type="checkbox"
          id="lessons-checkbox"
          checked={showAddressInput}
          onChange={handleCheckboxChange}
        />
        <label htmlFor="lessons-checkbox">שיעורים פרונטליים</label>
      </div>

      {showAddressInput && (
        <>
          <label>:רחוב (כתובת למורה לשיעורים פרונטליים)*</label>
          <Select
            options={streets.map((street) => ({ value: street.id, label: street.name }))}
            value={selectedStreet}
            onChange={handleStreetChange}
            className='select-city-input right-align'
            placeholder='בחר/י...'
            isRtl={true}
            noOptionsMessage={() => 'אין אפשרויות זמינות'}
            isClearable={true}
            required
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'right',
              }),
              singleValue: (provided) => ({
                ...provided,
                textAlign: 'right',
              }),
            }}
          />

          <label>:מספר בית*</label>
          <input
            type="text"
            name="house"
            value={addressDetails.house}
            onChange={handleAddressDetailsChange}
            required
          />

          <label>:קומה</label>
          <input
            type="number"
            name="floor"
            value={addressDetails.floor}
            onChange={handleAddressDetailsChange}
          />

          <label>:דירה</label>
          <input
            type="text"
            name="apartment"
            value={addressDetails.apartment}
            onChange={handleAddressDetailsChange}
          />

          <label>:כניסה</label>
          <input
            type="text"
            name="entrance"
            value={addressDetails.entrance}
            onChange={handleAddressDetailsChange}
          />

          <label>:קוד אינטרקום</label>
          <input
            type="text"
            name="intercom"
            value={addressDetails.intercom}
            onChange={handleAddressDetailsChange}
          />
        </>
      )}

      <label>:מין*</label>
      <Select
      options={genderOptions}
      value={genderOptions.find((option) => option.value === formData.gender)}
      onChange={(selectedOption) =>
          setFormData({
          ...formData,
          gender: selectedOption ? selectedOption.value : null,
          })
      }
      className='select-city-input right-align'
      placeholder='בחר/י...'
      isRtl={true}
      isClearable={true}
      menuPortalTarget={document.body}
      styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
          option: (provided, state) => ({
          ...provided,
          textAlign: 'right',
          }),
          singleValue: (provided) => ({
          ...provided,
          textAlign: 'right',
          }),
      }}
      required
      />

    <label>:כיתה*</label>
      <Select
        options={gradeOptions}
        value={gradeOptions.find((option) => option.value === studentData.grade)}
        onChange={(selectedOption) =>
            setStudentData({
            ...studentData,
            grade: selectedOption ? selectedOption.label : null,
            })
        }
        className='select-city-input right-align'
        placeholder='בחר/י...'
        isRtl={true}
        isClearable={true}
        menuPortalTarget={document.body}
        styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
            option: (provided, state) => ({
            ...provided,
            textAlign: 'right',
            }),
            singleValue: (provided) => ({
            ...provided,
            textAlign: 'right',
            }),
        }}
        required
        />

      <label>:קישור לפרופיל ברשת חברתית</label>
        <input
          type="text"
          name="social_media_profile"
          value={formattedSocialMedia}
          onChange={handleSocialMediaChange}
        />

        <label>:סטטוס*</label>
        <Select
          options={statusOptions}
          value={studentData.status}
          onChange={(selectedOption) =>
            setStudentData({
              ...studentData,
              status: selectedOption,
            })
          }
          className='select-city-input right-align'
          placeholder='בחר/י...'
          isRtl={true}
          isClearable={true}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
            option: (provided, state) => ({
              ...provided,
              textAlign: 'right',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'right',
            }),
          }}
          required
        />

      <label>:הערכה של מספר השיעורים הנדרש*</label>
        <Select
          options={lessonOptions}
          value={studentData.lessons_number}
          onChange={(selectedOption) =>
            setStudentData({
              ...studentData,
              lessons_number: selectedOption,
            })
          }
          className='select-city-input right-align'
          placeholder='בחר/י...'
          isRtl={true}
          isClearable={true}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
            option: (provided, state) => ({
              ...provided,
              textAlign: 'right',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'right',
            }),
          }}
          required
        />
        { selectedUserRole.value === "S" ?  
          <>
            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            {isLoading && <div className="circle-loader">...טוען</div>}
            <button className="submit-button" type="submit">
              שלח
            </button>
          </> :
          null
        }
      </div>
      <div className="right-side">
      { selectedUserRole.value === "P" ?  
          <>
          <label style={{marginTop:40}}>:?מי ממלא את הבקשה</label>
          <Select
            options={userOptions}
            value={selectedUserRole}
            onChange={handleUserRoleChange}
            className='select-city-input right-align'
            placeholder='בחר/י...'
            isRtl={true}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'right',
              }),
              singleValue: (provided) => ({
                ...provided,
                textAlign: 'right',
              }),
            }}
            required
          />
          </> :
          <h3>:פרטים אישיים נוספים - { selectedUserRole.value === "P" ? "תלמיד/ה" : "הורה" } (אופציונלי)</h3>
        }
        <label>:שם פרטי ההורה*</label>
        <input type="text" name="first_name" value={parentData.first_name} onChange={handleParentChange} />

        <label>:שם משפחה ההורה*</label>
        <input type="text" name="last_name" value={parentData.last_name} onChange={handleParentChange} />

        <label>:נייד ההורה*</label>
        { renderParentPhoneNumberWarningText() }
        <input
          type="tel"
          name='parent-phone'
          id='react-tel-input'
          className="react-tel-input"
          value={visiblePhoneNumber.parent}
          onChange={handlePhoneChange}
        />
        {showAddressInput && (
        <>
          <h3>:כתובת מגורים שונה (אופציונלי)</h3>
          <label>:עיר*</label>
          <Select
            options={allCities.map((city) => ({ value: city.id, label: city.name }))}
            value={parentCity}
            noOptionsMessage={() => 'אין אפשרויות זמינות'}
            onChange={handleParentCityChange}
            className="select-city-input right-align"
            placeholder='בחר/י...'
            isRtl={true}
            isClearable={true}
            name='city'
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'right',
              }),
              singleValue: (provided) => ({
                ...provided,
                textAlign: 'right',
              }),
            }}
          />

          <label>:רחוב*</label>
          <Select
            options={parentStreets.map((street) => ({ value: street.id, label: street.name }))}
            value={selectedParentStreet}
            onChange={handleParentStreetChange}
            className='select-city-input right-align'
            placeholder='בחר/י...'
            noOptionsMessage={() => 'אין אפשרויות זמינות'}
            isRtl={true}
            isClearable={true}
            name='street'
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
              option: (provided, state) => ({
                ...provided,
                textAlign: 'right',
              }),
              singleValue: (provided) => ({
                ...provided,
                textAlign: 'right',
              }),
            }}
          />

          <label>:מספר בית*</label>
          <input
            type="text"
            name="house"
            value={parentAddressDetails.house}
            onChange={handleParentAddressDetailsChange}
          />

          <label>:קומה</label>
          <input
            type="number"
            name="floor"
            value={parentAddressDetails.floor}
            onChange={handleParentAddressDetailsChange}
          />

          <label>:דירה</label>
          <input
            type="text"
            name="apartment"
            value={parentAddressDetails.apartment}
            onChange={handleParentAddressDetailsChange}
          />

          <label>:כניסה</label>
          <input
            type="text"
            name="entrance"
            value={parentAddressDetails.entrance}
            onChange={handleParentAddressDetailsChange}
          />

          <label>:קוד אינטרקום</label>
          <input
            type="text"
            name="intercom"
            value={parentAddressDetails.intercom}
            onChange={handleParentAddressDetailsChange}
          />
        </>
      )}
        { selectedUserRole.value === "P" ?  
          <>
            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}

            {isLoading && <div className="circle-loader">...טוען</div>}
            <button className="submit-button" type="submit">
              שלח
            </button>
          </> :
          null
        }
      </div>
    </form>
  );
};

export default StudentApplicationForm;
