export const sendFormRequest = async (url, method = 'POST', data = null) => {
    const response = await fetch(url, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: data ? JSON.stringify(data) : null,
    });

    return response;
};
