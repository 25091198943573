import React, { useState, useEffect } from 'react';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import './TeacherApplicationForm.css';
import { sendFormRequest } from './api';

const domain = 'https://api.meveen.org';

const TeacherApplicationForm = ({ setShowModal=null }) => {
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    dob: '',
    bio: '',
    gender: '',
    rate: '',
    lessons_type: '',
    subjects: [],
    cities: []
  });

  const [genderOptions] = useState([
    { value: 'F', label: 'נקבה' },
    { value: 'M', label: 'זכר' },
  ]);

  const [lessonTypeOptions] = useState([
    { value: 'A', label: 'הכל' },
    { value: 'O', label: 'אונליין' },
    { value: 'I', label: 'פרונטלי' },
  ]);

  const [allCities, setAllCities] = useState([]);
  const [allSubjects, setAllSubjects] = useState([]);
  const [error, setError] = useState(null);
  const [hourlyRate, setHourlyRate] = useState('');
  const [formattedRate, setFormattedRate] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [visiblePhoneNumber, setVisiblePhoneNumber] = useState('');
  const [passedLocation, setPassedLocation] = useState(false);
  const [isValid, setIsValid] = useState({
    error: '',
    passed: true
  });
  const [isLoading, setIsLoading] = useState(false);
  const [rateThreshold, setRateThreshold] = useState(0);
  const [teachingCertificate, setTeachingCertificate] = useState(null);
  const [specialEdCertificate, setSpecialEdCertificate] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [isCertifiedTeacher, setIsCertifiedTeacher] = useState(false);
  const [isSpecialEdTeacher, setIsSpecialEdTeacher] = useState(false);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await sendFormRequest(`${domain}/api/school/subjects/`, 'GET');

        if (response.ok) {
          const data = await response.json();
          setAllSubjects(data);
        } else {
          console.error('Failed to fetch subjects');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const fetchCities = async () => {
      try {
        const response = await sendFormRequest(`${domain}/api/location/cities/`, 'GET');

        if (response.ok) {
          const data = await response.json();
          setAllCities(data);
        } else {
          console.error('Failed to fetch cities');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchCities();
    fetchSubjects();
  }, []);

  // Handlers for new fields
  const handleFileChange = (e, setFile) => {
    setFile(e.target.files[0]);
  };

  const handleCheckboxChange = (e, setCheckbox) => {
    setCheckbox(e.target.checked);
  };

  const getColorClass = () => {
    const { rate } = formData;

    if (rate >= 10 + rateThreshold && rate <= 40 + rateThreshold) {
      return 'green';
    } else if (rate > 40 + rateThreshold && rate <= 70 + rateThreshold) {
      return 'pulsing';
    } else if (rate > 65 + rateThreshold && rate <= 70 + rateThreshold) {
      return 'yellow';
    } else if (rate > 80 + rateThreshold) {
      return 'red';
    } else {
      return '';
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRateChange = (e) => {
    const { name, value } = e.target;
    setHourlyRate(value);

    const formattedValue = (value / 4) * 3;
    setFormattedRate(formattedValue);

    setFormData({
      ...formData,
      [name]: formattedValue,
    });
  };
    
  const handlePhoneChange = (e) => {
    const pattern = /^(972|05)/;
    const countryPattern = /^(972)/;
  
    let inputValue = e.target.value.replace(/[^\d+]/g, '').slice(0, 13);
    let numericValue = inputValue.replace(/\D/g, '');
  
    const isValidInput = pattern.test(numericValue);
  
    let errorMessage = '';
    let formattedNumber = '';
  
    if (isValidInput) {
      if (countryPattern.test(numericValue)) {
        formattedNumber = `+972-${numericValue.slice(3, 5)}-${numericValue.slice(5, 8)}-${numericValue.slice(8, 10)}-${numericValue.slice(10, 12)}`;
      } else {
        formattedNumber = `05${numericValue.slice(2, 3)}-${numericValue.slice(3, 6)}-${numericValue.slice(6, 8)}-${numericValue.slice(8, 10)}`;
      }
    } else if (inputValue.length > 3) {
      errorMessage = "נא להזין מספר טלפון ישראלי חוקי שמתחיל ב-972 או 05.";
    } else {
      formattedNumber = inputValue;
    }
  
    setVisiblePhoneNumber(formattedNumber.replace(/-+$/, ''));
  
    setIsValid({
      error: errorMessage,
      passed: isValidInput,
    });
  
    if (isValidInput) {
      numericValue = countryPattern.test(numericValue) ? 
      numericValue.slice(3) : numericValue.slice(1);
  
      if (numericValue.length === 9) {
        setFormData({
          ...formData,
          phone_number: `+972${numericValue}`,
        });
      }
    }
  };
      

  const handleCitiesChange = (selectedCities) => {
    setFormData({
      ...formData,
      cities: selectedCities,
    });
  };

  const handleSubjectsChange = (selectedSubjects) => {
    setFormData({
      ...formData,
      subjects: selectedSubjects,
    });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!formData.phone_number || formData.phone_number.length !== 13) {
      setError('בבקשה הכנס/י מספר טלפון תקין.');
      setIsLoading(false);
      return;
    }

    const formDataWithoutSubjects = { ...formData };
    delete formDataWithoutSubjects.cities;
    delete formDataWithoutSubjects.subjects;
    delete formDataWithoutSubjects.rate;
    
    const citiesValues = formData.cities.map(city => city.value);
    const subjectsValues = formData.subjects.map(subject => subject.value);
    const rate = formData.rate;

    const randomPassword = Math.random().toString(36).slice(-8);

      try {
        const response = await sendFormRequest(`${domain}/api/teacher/teachers/`, 'POST', {
          user: {
            ...formDataWithoutSubjects,
            password: randomPassword
          },
          subjects: subjectsValues,
          cities: citiesValues,
          rate: rate
        });

        if (response.ok) {
          setError(null);
          setIsLoading(false);
          setSuccessMessage('!הבקשה הוגשה בהצלחה');
          setTimeout(() => {
            setSuccessMessage(null);
            if (setShowModal) {
              setShowModal(false);
            }
          }, 2000);
        } else {
          setIsLoading(false);
          const errorData = await response.json();
          const formattedError = Object.entries(errorData)
            .map(([field, message]) => {
              let formattedMessage;

              if (Array.isArray(message) && message.length > 0) {
                formattedMessage = message.join(', ');
              } else if (typeof message === 'object' && message !== null) {
                formattedMessage = Object.values(message).join(', ');
              } else {
                formattedMessage = String(message);
              }

              return `${formattedMessage}`;
            })
            .join('\n');
          setError(formattedError);
        }
      } catch (error) {
        setIsLoading(false);
        setError(error.message);
      }
  };

  const renderWarningText = () => {
    const { rate } = formData;

    if (rate > 70 + rateThreshold) {
      return <div className="warning-text">מורים אחרים גובים מחיר נמוך יותר</div>;
    }

    return null;
  };

  const renderPhoneNumberWarningText = () => {
    const { error, passed } = isValid;

    if (!passed) {
      return <div className="warning-text">{ error }</div>;
    }

    return null;
  };

  // useEffect(() => {
  //   console.log(formData)
  // }, [formData]);

  return (
      <form className="teacher-application-form" onSubmit={handleSubmit} method='POST'>


      <label>:אימייל*</label>
      <input type="email" name="email" value={formData.email} onChange={handleChange} required />

      <label>:שם פרטי*</label>
      <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />

      <label>:שם משפחה*</label>
      <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />

      <label>:נייד*</label>
      { renderPhoneNumberWarningText() }
      <input
        type="tel"
        id='react-tel-input'
        className="react-tel-input"
        value={visiblePhoneNumber}
        onChange={handlePhoneChange}
        required
      />

      <label>:תחום לימוד*</label>
        <Select
          isMulti
          options={allSubjects.map(subject => ({ value: subject.id, label: subject.name }))}
          value={formData.subjects}
          onChange={handleSubjectsChange}
          className='select-city-input right-align'
          placeholder='בחר/י... (מותרות מספר אפשרויות)'
          isRtl={true}
          noOptionsMessage={() => 'אין אפשרויות זמינות'}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            menu: provided => ({ ...provided, marginTop: 0, marginBottom: 0 }),
            option: (provided, state) => ({
              ...provided,
              textAlign: 'right',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'right',
            }),
          }}
          required
        />

        <label>:עיר*</label>
        <Select
          isMulti
          options={allCities.map(city => ({ value: city.id, label: city.name }))}
          value={formData.cities}
          onChange={(selectedOption) => {
            handleCitiesChange(selectedOption);
          }}
          className='select-city-input right-align'
          placeholder='בחר/י... (מותרות מספר אפשרויות)'
          isRtl={true}
          isClearable={true}
          menuPortalTarget={document.body}
          noOptionsMessage={() => 'אין אפשרויות זמינות'}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            menu: provided => ({ ...provided, marginTop: 0, marginBottom: 0 }),
            option: (provided, state) => ({
              ...provided,
              textAlign: 'right',
            }),
            singleValue: (provided) => ({
              ...provided,
              textAlign: 'right',
            }),
          }}
          required
        />

      <label>:תאריך לידה*</label>
      <input type="date" name="dob" value={formData.dob} onChange={handleChange} required />

      <label>:מין*</label>
      <Select
      options={genderOptions}
      value={genderOptions.find((option) => option.value === formData.gender)}
      onChange={(selectedOption) =>
          setFormData({
          ...formData,
          gender: selectedOption ? selectedOption.value : null,
          })
      }
      className='select-city-input right-align'
      placeholder="בחר/י..."
      isRtl={true}
      isClearable={true}
      menuPortalTarget={document.body}
      styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
          option: (provided, state) => ({
          ...provided,
          textAlign: 'right',
          }),
          singleValue: (provided) => ({
          ...provided,
          textAlign: 'right',
          }),
      }}
      required
      />

        <label>:סוג השיעורים*</label>
        <Select
        options={lessonTypeOptions}
        value={lessonTypeOptions.find((option) => option.value === formData.lessons_type)}
        onChange={(selectedOption) =>
            setFormData({
            ...formData,
            lessons_type: selectedOption ? selectedOption.value : null,
            })
        }
        className='select-city-input right-align'
        placeholder="בחר/י..."
        isRtl={true}
        isClearable={true}
        defaultValue={'A'}
        menuPortalTarget={document.body}
        styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, marginTop: 0, marginBottom: 0 }),
            option: (provided, state) => ({
            ...provided,
            textAlign: 'right',
            }),
            singleValue: (provided) => ({
            ...provided,
            textAlign: 'right',
            }),
        }}
        required
        />
        <label>:ביוגרפיה*</label>
        <textarea
          name="bio"
          value={formData.bio}
          onChange={handleChange}
          required
          placeholder="ספר/י על עצמך..."
          className="bio-textarea">
        </textarea>
        <label htmlFor="teachingCertificate">העלה תעודת הוראה</label>
        <input type="file" id="teachingCertificate" onChange={(e) => handleFileChange(e, setTeachingCertificate)} />

        <label htmlFor="specialEdCertificate">העלה תעודה לחינוך צרכים מיוחדים</label>
        <input type="file" id="specialEdCertificate" onChange={(e) => handleFileChange(e, setSpecialEdCertificate)} />

        <label htmlFor="profileImage">:תמונת פרופיל</label>
        <input type="file" id="profileImage" onChange={(e) => handleFileChange(e, setProfileImage)} />

        <label>
          <input type="checkbox" checked={isCertifiedTeacher} onChange={(e) => handleCheckboxChange(e, setIsCertifiedTeacher)} />
          :מורה מוסמך
        </label>

        <label>
          <input type="checkbox" checked={isSpecialEdTeacher} onChange={(e) => handleCheckboxChange(e, setIsSpecialEdTeacher)} />
          :צרכים מיוחדים
        </label>

        <label>:ספק תעריף סביר שאת/ה מוכן/נת לשיעור 60 דקי*</label>
        {renderWarningText()}
        <input type="number" name="rate" value={hourlyRate} onChange={handleRateChange} className={getColorClass()} required />

        {formattedRate && <div className="formatted-rate-message">{formattedRate}</div>}

        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        {isLoading && <div className="circle-loader">...טוען</div>}
        <button className='submit-button' type="submit">שלח</button>
      </form>
  );
};

export default TeacherApplicationForm;

